<template>
	<modal ref="modalCrearCarpeta" :titulo="`${crear ? 'Crear' : 'Editar'} carpeta de cupones`" icon="carpeta_nueva"
		no-aceptar adicional="Guardar" @adicional="guardar">
		<ValidationObserver ref="validarCarpeta" tag="div" class="row mx-0 justify-center my-3">
			<ValidationProvider v-slot="{ errors }" rules="required" name="Nombre de carpeta" tag="div" class="col-10">
				<p class="text-general f-15 pl-3">Nombre de carpeta</p>
				<el-input v-model="form.nombre" show-word-limit maxlength="40" />
				<p class="text-danger f-12 pl-3"> {{ errors[0] }} </p>
			</ValidationProvider>
		</ValidationObserver>
	</modal>
</template>

<script>
import Cupones from "~/services/cupones/cupones";
export default {
	data() {
		return {
			form: {
				nombre: "",
			},
			crear: true,
		};
	},
	computed: {
		tipoVista() {
			if (this.$route.name === 'admin.cupones.clientes.lista') return 1
			if (this.$route.name === 'admin.cupones.lechero.lista') return 2
			if (this.$route.name === 'tendero.cupones') return 1
			return 0
		}
	},
	methods: {
		toggle() {
			this.form.nombre = "";
			this.$refs.modalCrearCarpeta.toggle();
		},
		editar(carpeta) {
			this.crear = false;
			this.form.id = carpeta.id;
			this.form.nombre = carpeta.nombre;
			this.$refs.modalCrearCarpeta.toggle();
		},
		async guardar() {
			const valid = await this.$refs.validarCarpeta.validate();
			if (this.crear) {
				if (!valid) {
					this.notificacion('', 'campos obligatorios', 'warning');
					return
				}
				this.crearCarpeta();
			}
			if (!this.crear) {
				if (valid) {
					this.editarCarpeta();
				}
			}
		},
		async crearCarpeta() {
			try {
				this.form.destinatario = this.tipoVista
				const { data } = await Cupones.crearCarpeta(this.form)
				this.$emit('update', data.data)
				this.notificacion('', 'Nueva carpeta agregada correctamente', 'success')
				this.$refs.modalCrearCarpeta.toggle();
			} catch (e) {
				this.error_catch(e);
			}
		},
		async editarCarpeta() {
			try {
				const { data } = await Cupones.editarCarpeta(this.form)
				this.$emit('edit', data.data)
				this.notificacion('', 'Carpeta actualizada correctamente', 'success')
				this.$refs.modalCrearCarpeta.toggle();
			} catch (e) {
				this.error_catch(e);
			}
		},
	},
};
</script>
